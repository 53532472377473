<template>
  <div id="kt_bside" class="d-flex flex-column flex-root" v-if="isAuthenticated">

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="py-3 py-lg-8 d-flex flex-row flex-column-fluid page" style="background-position: center bottom; background-repeat: repeat; background-size: 350px; background-image: url(media/svg/humans/bg-aside2.svg)">

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div class="container">
              
                <!-- set progressbar -->
                <vue-progress-bar></vue-progress-bar>
                
                <div class="d-flex flex-row mt-5">
                  <b-button type="button" @click="$router.push({ name: 'dashboard' })" class="subheader-title badge badge-primary font-weight-bold my-2 mr-3">
                    <span class="svg-icon svg-icon-md svg-icon-white">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/project/kementan.svg" />
                        <!--end::Svg Icon-->
                    </span>
                    Poultrynesia
                  </b-button>
                </div>

                <div class="d-flex flex-row">
                    <!--begin::Content-->
                    <div class="flex-row-fluid mt-22 mt-lg-5">
                        <transition name="fade-in-up">
                            <router-view />
                        </transition>
                    </div>
                    <!--end::Content-->
                </div>
                
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout-Public",
  components: {
    KTFooter,
    KTScrollTop,
    Loader
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "auth-login" });
    }

    if (!this.currentUser.menu.statistik) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "layoutConfig",
      "currentUser"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  }
};
</script>

<style lang="scss">
    #kt_bside {
        background: #fff;
    }
</style>